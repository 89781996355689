<template>
  <transition name="fade">
    <!--begin::List Widget 5-->
    <div class="card" :class="widgetClasses" v-if="!state.loaderEnabled">
      <!--begin::Header-->
      <div class="card-header align-items-center border-0 mt-4">
        <div class="card-title">
          <h3 class="fw-bolder mb-0">{{$t("Dernières mises à jours sur vos tickets")}}</h3>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-5">

        <div v-if="!state.loaderEnabled && state.list.length == 0" class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10" style="width: 100%; margin-top: 0px;">
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucun ticket")}}</h4>
            <span>{{$t("Vous n'avez créer aucun ticket pour le moment.")}}</span>
          </div>
        </div>

        <!--begin::Timeline-->
        <div class="timeline-label">
          <!--begin::Item-->
          <div
            class="timeline-item"
            v-for="(item, index) in state.list"
            :key="index"
          >
            <!--begin::Label-->
            <div class="timeline-label fw-bolder text-gray-600 fs-6">
              {{ item.date }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="fa fa-genderless fs-1" :class="item.class"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div class="fw-mormal timeline-content text-muted ps-3">
              <span v-if="item.type == 'Ticket'" class="text-gray-800">
                <b><router-link :to="item.url">
                  {{$t("Ticket")}} #{{ item.id }}
                  <span v-if=" item.label" class='text-gray-600'> - {{ item.label }}</span>
                </router-link></b>
                <br><span v-html="item.desc2"></span>.
              </span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </div>
        <!--end::Timeline-->
      </div>
      <!--end: Card Body-->
    </div>
    <!--end: List Widget 5-->
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  setup() {
    const state = reactive({
      list: [],
      loaderEnabled: true,
    });
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getMAJTickets");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });

    return {
      state,
    };
  },
});
</script>
