<template>
<div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <div class="d-flex flex-column flex-xl-row" v-if="!state.loaderEnabled">
    <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <div class="card mb-5 mb-xl-8" style='min-height: 410px'>
        <div class="card-header border-0 pt-5 mt-5">
          <h3 class="card-title align-items-start flex-column mb-5">
            <span class="card-label fw-bolder fs-3 mb-1">{{$t("Vue d'ensemble")}}</span>

            <span class="text-muted mt-1 fw-bold fs-7" v-if="!state.loaderEnabled">{{$t("En quelques chiffres")}}</span>
          </h3>
        </div>

          <router-link to="/addupdateticket" class="link-primary fs-6 fw-bolder btn btn-primary mt-7 mx-7 mb-7">
            {{$t("Créer un nouveau ticket")}}
          </router-link>
        
        <div class="card-body pt-5">
          <StockRefs link tickets references sites/>
        </div>

      </div>
    </div>
    <div class="flex-lg-row-fluid ms-lg-5">
      <div class="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
        <div class="row g-5 g-xl-12">
          <div class="col-xl-12">
            
            <SyntheseProjet widget-classes="mb-12"></SyntheseProjet>

          </div>
        </div>
        <div class="row g-5 g-xl-12">
          <div class="col-xl-12">
            <ActivitesTickets widget-classes="mb-12"></ActivitesTickets>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import ActivitesTickets from "@/views/still/deploy/activitesTickets.vue";
import SyntheseProjet from "@/views/still/deploy/syntheseProjet.vue";
import StockRefs from "./stockRefs.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    ActivitesTickets,
    SyntheseProjet,
    StockRefs,
  },
  setup() {

    const router = useRouter();

    const affEquipByType = (type) => {
      router.push({  name: "listEquipementbyType", params: { code: type } });
    }

    const state = reactive({
      loaderEnabled: true,
    });
    const { t } = useI18n()

    const Plur = (item) => {
      if (item>0) return t('s');
    }

    onMounted(async () => {
      state.loaderEnabled = false;
    });

    return {
      state,
      affEquipByType,
      Plur,
      model: {}
    };
  },
});
</script>
