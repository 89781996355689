<template>
  <transition name="fade" v-if="state.projet>0">
    <!--begin::List Widget 5-->
    <div class="card" :class="widgetClasses" v-if="!state.loaderEnabled">
      <!--begin::Header-->
      <div class="card-header align-items-center border-0 mt-4">
        <div class="card-title">
          <h3 class="fw-bolder mb-0">{{$t("Synthèse de vos projets")}}</h3>
        </div>

        <el-select v-model="state.projet" class="m-2 w-400px" placeholder="Select" size="large"  @change="changeProjet" >
          <el-option
            v-for="item in state.list"
            :key="item.pro_seq"
            :label="item.pro_nom_projet"
            :value="item.pro_seq"
          />
        </el-select>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-0">
        <apexchart width="100%" height="300" :options="options" :series="state.series" :key="state.projet" @click="clickChart"></apexchart>

      </div>
      <!--end: Card Body-->
    </div>
    <!--end: List Widget 5-->
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  setup() {
    const state = reactive({
      list: [] as any,
      loaderEnabled: true,
      projet: 0,
      series: [] as any,

    });
    const { t } = useI18n()

    onMounted(async () => {
      let rEch = await mAxiosApi.prototype.getAxios("/getTicketProjets");
      state.list = rEch.results;
      if (state.list.length > 0){
        state.projet = state.list[0].pro_seq.toString();
        changeProjet();
      } 
      state.loaderEnabled = false;
    });

    const options = {
      //colors: ['#64b5f6', '#f8bbd0', '#f9d490', '#DAF7A6', '#FFC300'],
      
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        events: {
          click: function(event, chartContext, config) {
            //console.log(event); 
          }
        },
        toolbar: {
          show: false,
        },
        animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    }
      },
      plotOptions: {
        bar: {
          horizontal: true
        },
      },
      stroke: {
        width: 4,
        curve: 'smooth',
        lineCap: 'butt',
        colors: ["#ffffff"]
      },
      title: {
        text: ""
      },
      xaxis: {
        categories: [""]
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " " + t("Ticket(s)");
          }
        }
      },
      fill: {
        opacity: 0.75,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        offsetX: 0
      }

    };

    const router = useRouter();

    const clickChart = (event,chartContext, config) => {
      //console.log(state.series[config.seriesIndex].name);
      router.push({  name: "ticketWst", params: { status: state.series[config.seriesIndex].name } });
    };

    const changeProjet = async () => {
      let rEch = await mAxiosApi.prototype.getAxios("/getTicketProjet/" + state.projet);
      const newArraySeries = [] as any;
      
      rEch.results.forEach((el) => {
        el.categ = JSON.parse(el.categ);
        newArraySeries.push(el.categ);
      })      
      state.series = newArraySeries;
    };

    return {
      state,
      options,
      changeProjet,
      clickChart
    };
  },
});
</script>
